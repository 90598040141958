import { render, staticRenderFns } from "./TslcTextField.vue?vue&type=template&id=65f632f4&scoped=true"
import script from "./TslcTextField.vue?vue&type=script&lang=ts"
export * from "./TslcTextField.vue?vue&type=script&lang=ts"
import style0 from "./TslcTextField.vue?vue&type=style&index=0&id=65f632f4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f632f4",
  null
  
)

export default component.exports