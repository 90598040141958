
import { Component, Vue } from 'vue-property-decorator'
import TslcButton from '@/components/TslcButton.vue'

@Component({
  components: {
    TslcButton
  }
})
export default class Home extends Vue {
}
