
import { Component, Prop, Vue } from 'vue-property-decorator'
import TslcButton from '@/components/TslcButton.vue'
import TslcTextField from '@/components/TslcTextField.vue'
import TslcTextArea from '@/components/TslcTextArea.vue'
import TslcRadioButton from '@/components/TslcRadioButton.vue'
import TslcRecordingInput from '@/components/TslcRecordingInput.vue'
import TslcPlayer from '@/components/TslcPlayer.vue'
import { IQuestion } from '@/tools/interfaces'

@Component({
  components: {
    TslcRadioButton,
    TslcButton,
    TslcTextField,
    TslcTextArea,
    TslcRecordingInput,
    TslcPlayer
  }
})
export default class Question extends Vue {
  @Prop({ default: true }) question!: IQuestion
  windowWidth: number = window.innerWidth
  defaultImageWitdh = 208
  defaultMobileScale = 1

  get calculatedImageWith (): number {
    return (this.windowWidth <= 784 ? this.question.image?.mobileWidth : this.question.image?.width) || this.defaultImageWitdh
  }

  onResize (): void {
    this.windowWidth = window.innerWidth
  }

  audioRecorder (event: Blob) :void {
    this.$emit('audioRecorded', event)
  }

  mounted (): void {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }

  beforeDestroy (): void {
    window.removeEventListener('resize', this.onResize)
  }
}
