import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    playingAudio: false
  },
  mutations: {
    setPlayingAudio (state, playingAudio) {
      state.playingAudio = playingAudio
    }
  },
  actions: {
    updatePlayingAudio (context, playingAudio) {
      context.commit('setPlayingAudio', playingAudio)
    }
  },
  modules: {
  }
})
