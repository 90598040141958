
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TSLCTextArea extends Vue {
  @Prop() private placeholder!: string
  @Prop() private value!: string
  @Prop() private rows!: number
  @Prop({ default: false }) private disabled!: boolean
}
