export const RESPONSE = {
  REQUIRED: 'This field is required.',
  EMAIL: {
    VALID: 'The field must be a valid email.',
    NAME_POINTS: 'Points are not allowed at the beginning and end of the name.'
  }
}

export const PATTERNS = {
  EMAIL: {
    VALID: /^[\w-][\w.-]{2,60}[\w-]@[\w-]{3,20}(\.[a-z-A-Z]{2,3}){1,2}$/,
    NAME_POINTS: /^(?=\.)|(?=\S*\.@)\S*$/
  }
}

export const VALIDATORS = {
  REQUIRED: (val: string): boolean | string => !!val || RESPONSE.REQUIRED,
  EMAIL: {
    VALID: (val: string): boolean | string => (PATTERNS.EMAIL.VALID.test(val) && !PATTERNS.EMAIL.NAME_POINTS.test(val)) || RESPONSE.EMAIL.VALID
  }
}
