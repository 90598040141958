export enum QUESTION_TYPE {
  TEXT = 'TEXT',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export enum ANSWER_TYPE {
  TEXT = 'TEXT',
  AUDIO = 'AUDIO',
  ALTERNATIVE = 'ALTERNATIVE',
}

export enum QUIZ_PRIORITY {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
}

export enum QUIZ_STATUS {
  FINISHED = 'FINISHED',
  INCOMPLETE = 'INCOMPLETE',
}

export enum DEVICE_TYPE {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

export enum USER_LEVEL {
  NOOB = 'NOOB',
  NORMAL = 'NORMAL',
  PRO = 'PRO',
}
