<template>
  <div
    class="vue-audio-recorder"
    :class="{
      'active': isRecording,
      'paused': isPaused
    }"
    @mousedown="start"
    @mouseup="stop"
  >
    <v-icon left color="white">
      mdi-microphone
    </v-icon>
    {{ isRecording ? 'Recording...' : 'Record' }}
  </div>
</template>

<script>
// eslint-disable-next-line import/no-duplicates
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc'

export default {
  name: 'TslcAudioRecord',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      isRecording: false,
      isPaused: false,
      recorder: null,
      audioUrl: null,
      timer: 0,
      limitTime: 20
    }
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    limitTime () {
      if (this.limitTime <= 0) {
        this.stop()
      }
    }
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    audioDuration () {
      return 20 - this.limitTime
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    isEdge () {
      return navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob)
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    isSafari () {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    }
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async start () {
      if (!this.isRecording) {
        await this.prepare()
        this.isRecording = true
        this.$emit('start')
        this.recorder.startRecording()
      } else {
        await this.stop()
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async stop () {
      if (this.recorder) {
        this.isRecording = false
        this.$emit('stop')
        await this.recorder.stopRecording()
        const blob = await this.recorder.getBlob()
        this.$emit('result', blob)
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async prepare () {
      const options = {
        type: 'audio',
        numberOfAudioChannels: this.isEdge ? 1 : 2,
        checkForInactiveTracks: true,
        bufferSize: 16384
      }

      if (this.isSafari || this.isEdge) {
        options.recorderType = StereoAudioRecorder
      }

      if (navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
        options.sampleRate = 48000 // or 44100 or remove this line for default
      }

      if (this.isSafari) {
        options.sampleRate = 44100
        options.bufferSize = 4096
        options.numberOfAudioChannels = 2
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true
      })
      this.recorder = new RecordRTCPromisesHandler(stream, options)
    }
  }
}
</script>

<style scoped lang="scss">
.vue-audio-recorder {
  position: relative;
  border-radius: 20px;
  background: #DD4444;
  width: 200px;
  height: 56px;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgb(232 76 61 / 70%);
  color: white;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #DD5555;
  }

  &.active {
    background-color: #ef5350;
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(239, 83, 80, 0.1);
    background-color: #E53935;
    transform: scale(0.9);
  }
}
</style>
