import { render, staticRenderFns } from "./TslcRecordingInput.vue?vue&type=template&id=79d85911&scoped=true"
import script from "./TslcRecordingInput.vue?vue&type=script&lang=ts"
export * from "./TslcRecordingInput.vue?vue&type=script&lang=ts"
import style0 from "./TslcRecordingInput.vue?vue&type=style&index=0&id=79d85911&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d85911",
  null
  
)

export default component.exports