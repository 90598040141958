<template>
  <div class="audio-message-content">
    <a v-if="loaded" class="play-pause-btn" href="#" @click.prevent="togglePlay">
      <svg key="pause" x="0px" y="0px" v-if="playing" style="width:27px;height:27px;margin: -13px" viewBox="0 0 24 24">
        <path fill="#243674" d="M14,19H18V5H14M6,19H10V5H6V19Z"/>
      </svg>
      <svg key="play" x="0px" y="0px" v-else style="width:19px;height:19px;margin: -9px" width="14" height="17"
           viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.887 9.27299C13.5136 8.88132 13.5136 7.96866 12.887 7.57699L1.87375 0.693741C1.2077 0.277461 0.34375 0.756304 0.34375 1.54174V15.3082C0.34375 16.0937 1.2077 16.5725 1.87375 16.1562L12.887 9.27299Z"
          fill="#243674"/>
      </svg>
    </a>
    <div v-else class="loading">
      <div class="spinner"></div>
    </div>

    <div class="controls mx-1">
      <div class="slider" data-direction="horizontal">
        <div class="progress" :style="{width: percent}">
        </div>
      </div>
      <span class="current-time" v-if="type !== 'recording'">{{ currentTime }}/</span>
      <span class="total-time">{{ totalTime }}</span>
    </div>

    <div class="d-flex justify-space-between align-center mx-3 volumen-controls" v-if="type !== 'recording'">
      <svg @click="changeVolumen(false)" class="ma-1" width="15" height="15" viewBox="0 0 15 15" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.30088 0.352795C8.96274 -0.119968 9.88212 0.353155 9.88212 1.16653V13.7503C9.88212 14.5637 8.96275 15.0368 8.30088 14.5641L4.11755 11.576H2C0.895432 11.576 0 10.6806 0 9.57598V5.34089C0 4.23632 0.89543 3.34089 2 3.34089H4.11755L8.30088 0.352795Z"
          :fill="volumenActivated ? '#67757C': '#243674'"/>
        <path
          d="M12.3484 11.4983C13.3702 10.4581 14.0005 9.03203 14.0005 7.45871C14.0005 5.87878 13.3649 4.44731 12.3354 3.40604"
          :stroke="volumenActivated ? '#67757C': '#243674'" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
      </svg>

      <svg @click="changeVolumen(true)" class=" ma-1" width="19" height="19" viewBox="0 0 19 19" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.71051 2.71283C9.37238 2.24006 10.2917 2.71319 10.2917 3.52656V15.4735C10.2917 16.2869 9.37238 16.76 8.71051 16.2872L4.75008 13.4584H2.79175C1.68718 13.4584 0.791748 12.5629 0.791748 11.4584V7.5417C0.791748 6.43713 1.68718 5.54171 2.79175 5.54171H4.75008L8.71051 2.71283Z"
          :fill="volumenActivated ? '#243674':'#67757C'"/>
        <path
          d="M12.6618 13.3834C13.6442 12.3835 14.2501 11.0125 14.2501 9.50003C14.2501 7.9812 13.6391 6.60507 12.6494 5.60406"
          :stroke="volumenActivated ? '#243674':'#67757C'" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
        <path
          d="M14.8662 3.34232C16.4421 4.91821 17.4168 7.0953 17.4168 9.50004C17.4168 11.9048 16.4421 14.0819 14.8662 15.6578"
          :stroke="volumenActivated ? '#243674':'#67757C'" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
      </svg>
    </div>

    <audio ref="audio" :src="audioUrl"
           @loadedmetadata="loadedMetaData" @canplay="canPlay" @timeupdate="timeUpdate" @ended="ended"
    ></audio>
  </div>
</template>

<script lang="js">
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TSLCPlayer',
  props: {
    audioUrl: String,
    type: {
      type: String,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      default () {
        return 'default'
      }
    },
    audioDuration: {
      type: Number,
      required: false
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: () => ({
    audio: undefined,
    loaded: false,
    playing: false,
    currentTime: '00:00',
    totalTime: '00:00',
    percent: '0%',
    draggableClasses: ['pin'],
    currentlyDragged: null,
    volumenActivated: true
  }),
  watch: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    playingAudio () {
      if (!this.playingAudio && this.playing) {
        this.audio.pause()
        this.playing = false
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    audioUrl () {
      this.loadedMetaData()
    }
  },
  computed: {
    ...mapState(['playingAudio'])
  },
  methods: {
    ...mapActions(['updatePlayingAudio']),
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    formatTime (time) {
      const min = Math.floor(time / 60)
      const sec = Math.floor(time % 60)
      return min + ':' + ((sec < 10) ? ('0' + sec) : sec)
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    loadedMetaData () {
      if (this.type === 'recording') {
        if (this.audioDuration) {
          this.totalTime = this.formatTime(this.audioDuration)
        } else {
          this.audio.addEventListener('durationchange', () => {
            if (this.audio.duration !== Infinity) {
              this.totalTime = this.formatTime(this.audio.duration)
            }
          })
        }
      } else {
        this.totalTime = this.formatTime(this.audio.duration)
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    canPlay () {
      this.loaded = true
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    timeUpdate () {
      const current = this.audio.currentTime
      const percent = (current / this.audio.duration) * 100

      this.percent = percent + '%'

      this.currentTime = this.formatTime(current)
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    ended () {
      this.playing = false
      this.audio.currentTime = 0
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    isDraggable (el) {
      let canDrag = false
      const classes = Array.from(el.classList)
      this.draggableClasses.forEach(draggable => {
        if (classes.indexOf(draggable) !== -1) {
          canDrag = true
        }
      })
      return canDrag
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    inRange (event) {
      const rangeBox = this.getRangeBox(event)
      const rect = rangeBox.getBoundingClientRect()
      const direction = rangeBox.dataset.direction
      if (direction === 'horizontal') {
        const min = rangeBox.offsetLeft
        const max = min + rangeBox.offsetWidth
        if (event.clientX < min || event.clientX > max) return false
      } else {
        const min = rect.top
        const max = min + rangeBox.offsetHeight
        if (event.clientY < min || event.clientY > max) return false
      }
      return true
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    togglePlay () {
      if (this.audio.paused) {
        this.audio.play()
        this.playing = true
        this.updatePlayingAudio(true)
      } else {
        this.audio.pause()
        this.playing = false
        this.updatePlayingAudio(false)
      }
    },
    // makePlay () {
    //   playpauseBtn.style.display = 'block'
    //   loading.style.display = 'none'
    // },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getRangeBox (event) {
      let rangeBox = event.target
      const el = this.currentlyDragged
      if (event.type === 'click' && this.isDraggable(event.target)) {
        rangeBox = event.target.parentElement.parentElement
      }
      if (event.type === 'mousemove') {
        rangeBox = el.parentElement.parentElement
      }
      return rangeBox
    },
    getCoefficient (event) {
      const slider = this.getRangeBox(event)
      const rect = slider.getBoundingClientRect()
      let K = 0
      if (slider.dataset.direction === 'horizontal') {
        const offsetX = event.clientX - slider.offsetLeft
        const width = slider.clientWidth
        K = offsetX / width
      } else if (slider.dataset.direction === 'vertical') {
        const height = slider.clientHeight
        const offsetY = event.clientY - rect.top
        K = 1 - offsetY / height
      }
      return K
    },
    rewind (event) {
      if (this.inRange(event)) {
        this.audio.currentTime = this.audio.duration * this.getCoefficient(event)
      }
    },
    changeVolumen (volumenActivated) {
      const audio = this.$refs.audio
      audio.volume = volumenActivated
      this.volumenActivated = volumenActivated
    }
  },
  mounted () {
    this.audio = this.$refs.audio
  }
}
</script>

<style scoped lang="scss">
.audio-message-content {
  min-width: 300px;
  max-width: 450px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 4px;
  user-select: none;
  -webkit-user-select: none;
  background-color: #fff;
  margin: 20px auto;

  .play-pause-btn {
    position: relative;
    width: 18px;
    height: 22px;
    cursor: pointer;

    svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -9px;
    }
  }

  .spinner {
    width: 18px;
    height: 18px;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/loading.png);
    background-size: cover;
    background-repeat: no-repeat;
    animation: spin 0.4s linear infinite;
  }

  .slider {
    flex-grow: 1;
    background-color: rgba(36, 54, 116, 0.4);
    cursor: pointer;
    position: relative;

    .progress {
      background-color: #243674;
      border-radius: inherit;
      position: absolute;
      pointer-events: none;
    }
  }

  .controls {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: #67757C;
    font-weight: 400;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;

    .slider {
      margin-left: 8px;
      margin-right: 8px;
      height: 4px;
      border-radius: 2px;

      .progress {
        width: 0;
        height: 100%;

        .pin {
          right: -8px;
          top: -6px;
        }
      }
    }

    span {
      cursor: default;
    }
  }
}

svg, img {
  display: block;
}

.volumen-control path {
  fill: #67757C;
  stroke: #67757C;

  &__active path {
    fill: #243674;
    stroke: #243674;
  }
}

@media screen and (max-width: 784px) {
  .audio-message-content {
    width: 100%;
    flex-wrap: wrap;

    .controls {
      font-size: 14px;
      width: 90%;
    }
  }

  .volumen-controls {
    margin-right: 0 !important;
    margin-left: auto !important;
  }
}

// Mobile max width 320px
@media screen and (max-width: 320px) {
  .audio-message-content {
    min-width: 250px;
  }
  .audio-message-content .controls {
    width: 80%;
  }
}

</style>
