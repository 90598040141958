
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TSLCTextField extends Vue {
  @Prop() private placeholder!: string
  @Prop() private value!: string
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: () => [] }) private rules!: Array<never>
}
