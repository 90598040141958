
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TslcButton from '@/components/TslcButton.vue'
import TslcPlayer from '@/components/TslcPlayer.vue'
import TslcTextField from '@/components/TslcTextField.vue'
import TslcAudioRecord from '@/components/TslcAudioRecord.vue'

@Component({
  components: {
    TslcButton,
    TslcPlayer,
    TslcTextField,
    TslcAudioRecord
  }
})
export default class TslcRecordingInput extends Vue {
  @Prop() private placeholder!: string
  @Prop() private value!: string
  @Prop({ default: false }) private disabled!: boolean

  limitTime = 20
  timer = 0
  finished = false
  audioUrl = ''

  get audioDuration (): number {
    return 20 - this.limitTime
  }

  @Watch('limitTime')
  watchLimitTime (): void {
    if (this.limitTime <= 0) {
      if (this.$refs.customRecordAudio) (this.$refs.customRecordAudio as Vue & { stopRecording: () => void }).stopRecording()
    }
  }

  startRecording (): void {
    clearInterval(this.timer)
    this.limitTime = 20
    this.timer = window.setInterval(() => {
      this.limitTime--
    }, 1000)
  }

  stopRecording (): void {
    clearInterval(this.timer)
  }

  onResult (data: Blob) :void {
    this.audioUrl = URL.createObjectURL(data)
    this.$emit('result', data)
  }
}
