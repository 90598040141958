import { render, staticRenderFns } from "./TslcButton.vue?vue&type=template&id=2bae9bea&scoped=true"
import script from "./TslcButton.vue?vue&type=script&lang=ts"
export * from "./TslcButton.vue?vue&type=script&lang=ts"
import style0 from "./TslcButton.vue?vue&type=style&index=0&id=2bae9bea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bae9bea",
  null
  
)

export default component.exports