
import { Component, Vue } from 'vue-property-decorator'
import TslcButton from '@/components/TslcButton.vue'
import TslcTextField from '@/components/TslcTextField.vue'
import TslcSelectField from '@/components/TslcSelectField.vue'
import TslcTextArea from '@/components/TslcTextArea.vue'
import Question from '@/components/Question.vue'
import TslcRadioButton from '@/components/TslcRadioButton.vue'
import TslcPlayer from '@/components/TslcPlayer.vue'
import greenStudentImageUrl from '@/assets/green_student.gif'
import pinkStudentImageUrl from '@/assets/pink_student.gif'
import purpleStudentImageUrl from '@/assets/purple_student.gif'
import lightblueStudentImageUrl from '@/assets/lightblue_student.gif'
import carlosImageUrl from '@/assets/carlos_question.jpg'
import superHeroImageUrl from '@/assets/super_hero.gif'
import clockImageUrl from '@/assets/clock.gif'
import { IQuestion, IQuiz } from '@/tools/interfaces'
import { ANSWER_TYPE, DEVICE_TYPE, QUESTION_TYPE, QUIZ_PRIORITY, QUIZ_STATUS, USER_LEVEL } from '@/tools/enums'
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import db, { app } from '@/utils/firebase-database'
import { VALIDATORS } from '@/tools/validators'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import { Route } from 'vue-router'

@Component({
  components: {
    TslcPlayer,
    TslcRadioButton,
    TslcButton,
    Question,
    TslcTextField,
    TslcTextArea,
    TslcSelectField
  },
  // eslint-disable-next-line
  beforeRouteLeave(to: Route, from: Route, next: any) {
    const confirm = window.confirm('If you exit the quiz, your answers will not be saved.')
    if (confirm) next()
  }
})
export default class Quiz extends Vue {
  userProgress = 0

  rules = {
    required: VALIDATORS.REQUIRED,
    validEmail: VALIDATORS.EMAIL.VALID
  }

  tempBlob: Blob | null = null

  currentQuiz: IQuiz = {
    code: -1,
    date: new Date(),
    device: '',
    email: '',
    name: '',
    phone: '',
    priority: '',
    status: '',
    city: ''
  }

  loading = false
  questions: Array<IQuestion> = [
    {
      statement: 'What do you think your current level of Spanish is?',
      questionType: QUESTION_TYPE.TEXT,
      answerType: ANSWER_TYPE.ALTERNATIVE,
      alternatives: [
        {
          statement: "I'm a newble. I have none or a very little knowledge of spanish"
        },
        {
          statement: "I have taken some lessons but I haven't practiced that much lately."
        },
        {
          statement: "I have recently taken some lessons and I'd like to continue."
        },
        {
          statement: 'Creo que soy un pro!'
        }
      ],
      showSubmitButton: false,
      response: 0
    },
    {
      statement: 'Oh, en serio?',
      secondarySentence: 'Vamos a ver...',
      questionType: QUESTION_TYPE.IMAGE,
      showSubmitButton: false,
      image: {
        url: purpleStudentImageUrl,
        width: 254,
        mobileWidth: 208
      },
      response: ''
    },
    {
      statement: 'Ok, how long ago have you had a Spanish class?',
      questionType: QUESTION_TYPE.TEXT,
      answerType: ANSWER_TYPE.ALTERNATIVE,
      alternatives: [
        {
          statement: 'Less than a month ago. '
        },
        {
          statement: 'Between one and six months ago.'
        },
        {
          statement: 'Between six months and a year ago.'
        },
        {
          statement: 'Over a year ago.'
        }
      ],
      showSubmitButton: false,
      response: 0
    },
    {
      header: 'Just a quick question to warm up:',
      statement: '¿Cómo estás?',
      questionType: QUESTION_TYPE.IMAGE,
      answerType: ANSWER_TYPE.TEXT,
      image: {
        url: greenStudentImageUrl,
        width: 308,
        mobileWidth: 270
      },
      response: '',
      showSubmitButton: true
    },
    {
      header: "Nice! Now, Let's get started with something easy: ",
      statement: 'Carlos________en Chicago y__________23 años.',
      questionType: QUESTION_TYPE.TEXT,
      answerType: ANSWER_TYPE.ALTERNATIVE,
      alternatives: [
        {
          statement: 'está/es.'
        },
        {
          statement: 'es/tiene.'
        },
        {
          statement: 'vive/tiene'
        },
        {
          statement: 'vive/es.'
        }
      ],
      showSubmitButton: true,
      response: 0
    },
    {
      header: 'Please, complete this sentence:',
      statement: '_______ las tres de la tarde.',
      image: {
        url: clockImageUrl,
        width: 117,
        mobileWidth: 117
      },
      questionType: QUESTION_TYPE.IMAGE,
      answerType: ANSWER_TYPE.ALTERNATIVE,
      alternatives: [
        {
          statement: 'Están'
        },
        {
          statement: 'Esa'
        },
        {
          statement: 'Eso'
        },
        {
          statement: 'Son'
        }
      ],
      response: 0,
      showSubmitButton: true
    },
    {
      header: 'And now this one:',
      statement: 'Yo________ muy preocupado ahora. Tengo muchos exámenes mañana.',
      questionType: QUESTION_TYPE.TEXT,
      answerType: ANSWER_TYPE.ALTERNATIVE,
      alternatives: [
        {
          statement: 'soy'
        },
        {
          statement: 'estoy '
        },
        {
          statement: 'tengo'
        },
        {
          statement: 'preocupar'
        }
      ],
      showSubmitButton: true,
      response: 0
    },
    {
      statement: 'Cómo fue tu fin de semana pasado? Qué hiciste?',
      secondarySentence: 'Record your audio response',
      image: {
        url: pinkStudentImageUrl,
        width: 188,
        mobileWidth: 188
      },
      questionType: QUESTION_TYPE.IMAGE,
      answerType: ANSWER_TYPE.AUDIO,
      showSubmitButton: true,
      response: ''
    },
    {
      statement: 'Please listen to the short story in the video and answer the question at the end in the box below.',
      videoUrl: 'https://www.youtube.com/embed/6OiI2TvY3oA?si=3iKDj2YJeP5QmFea',
      questionType: QUESTION_TYPE.VIDEO,
      answerType: ANSWER_TYPE.TEXT,
      rows: 5,
      showSubmitButton: true,
      response: ''
    },
    {
      statement: 'Qué está haciendo Carlos?',
      secondarySentence: 'You can make your answer as easy or as complex as you can. Carlos is the guy in the picture.',
      image: {
        url: carlosImageUrl,
        width: 280,
        mobileWidth: 179
      },
      questionType: QUESTION_TYPE.IMAGE,
      answerType: ANSWER_TYPE.AUDIO,
      showSubmitButton: true,
      response: ''
    },
    {
      header: 'Please express this sentence in Spanish',
      statement: '“My boss always gives me the documents in the mornig but today, he will probably give them to me at night”.',
      image: {
        url: lightblueStudentImageUrl,
        width: 207,
        mobileWidth: 168
      },
      questionType: QUESTION_TYPE.IMAGE,
      answerType: ANSWER_TYPE.TEXT,
      showSubmitButton: true,
      response: ''
    },
    {
      statement: 'Si pudieras tener tres superpoderes, ¿cuáles te gustaría tener?',
      image: {
        url: superHeroImageUrl,
        width: 228,
        mobileWidth: 142
      },
      questionType: QUESTION_TYPE.IMAGE,
      answerType: ANSWER_TYPE.TEXT,
      showSubmitButton: true,
      response: ''
    },
    {
      statement: 'Please listen to the short story in this video and record your answer to the question at the end.',
      videoUrl: 'https://www.youtube.com/embed/E0QqUuAKd6E?si=UH4EJD6XwBIzouY5',
      questionType: QUESTION_TYPE.VIDEO,
      answerType: ANSWER_TYPE.AUDIO,
      showSubmitButton: true,
      response: ''
    },
    {
      header: 'Our classrooms fill out quickly!',
      statement: 'How ready are you to start your Spanish lessons?',
      questionType: QUESTION_TYPE.TEXT,
      answerType: ANSWER_TYPE.ALTERNATIVE,
      alternatives: [
        {
          statement: "I'm ready! I could start even next week."
        },
        {
          statement: 'I could start in 2 or 3 weeks.'
        },
        {
          statement: 'Please, subscribe me to the Newsletter and keep me updated.'
        }
      ],
      showSubmitButton: false,
      response: 0
    }
  ]

  public async submitQuestion (): Promise<void> {
    if (this.userProgress === 1) {
      if ((this.$refs.userForm as Vue & { validate: () => boolean }).validate()) {
        if (!this.currentQuiz.id) {
          await this.saveQuizInformation()
        } else {
          await this.updateQuizInformation(this.currentQuiz.id)
        }
      } else {
        return
      }
    }

    if (!this.verifyCurrentReponse() && this.userProgress !== 3) {
      alert("If you don't have an answer, press the 'skip' button")
      return
    }

    if (this.userProgress >= 2 && this.userProgress !== 3) {
      await this.sendCurrentAnswer()
    }

    /*
    if user is in the second question and is noob
    or user is in the 15th question finish the quiz
    */
    if ((this.userProgress === 2 && this.userLevel === USER_LEVEL.NOOB) || this.userProgress === 15) {
      await this.finishQuiz()
    }

    await this.nextQuestion()
  }

  verifyCurrentReponse (): boolean {
    const currentQuestion = this.questions[this.currentQuestionIndex]

    if (currentQuestion.answerType === ANSWER_TYPE.ALTERNATIVE) {
      return currentQuestion.response >= 0
    } else if (currentQuestion.answerType === ANSWER_TYPE.AUDIO) {
      return !!this.tempBlob
    } else {
      return !!currentQuestion.response
    }
  }

  public async finishQuiz (): Promise<void> {
    this.currentQuiz.status = QUIZ_STATUS.FINISHED
    this.currentQuiz.priority = this.userPriority
    if (this.currentQuiz.id) {
      await this.updateQuizInformation(this.currentQuiz.id)
    } else {
      await this.saveQuizInformation()
    }

    if (this.userLevel !== USER_LEVEL.NOOB) {
      this.sendEmails()
    }
  }

  sendEmails (): void {
    const data = {
      fullName: this.currentQuiz.name,
      email: this.currentQuiz.email,
      phone: this.currentQuiz.phone,
      date: this.currentQuiz.date,
      priority: this.currentQuiz.priority
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    }
    fetch('https://us-central1-mailer-tslc-quiz.cloudfunctions.net/mailer', requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data)
      })
  }

  public async nextQuestion (): Promise<void> {
    // Pause player when a user change of question
    if (this.$store.state.playingAudio) {
      await this.$store.dispatch('updatePlayingAudio', false)
    }
    if (this.userProgress === 2) {
      /*
      If is the first question and select the option:
       - A => Send to the thank you page
       - B or C => Send to the third question of questions array
       - B or C => Send to the second question of questions array but no show the third question
       */
      if (this.userLevel === USER_LEVEL.NOOB) {
        // Send response to database
        this.userProgress = 16
        // Send email
      } else if (this.userLevel === USER_LEVEL.NORMAL) {
        // Send response to database
        this.userProgress = 4
      } else if (this.userLevel === USER_LEVEL.PRO) {
        // Send response to database
        this.userProgress = 3
      }
    } else if (this.userProgress === 3) {
      if (this.userLevel === USER_LEVEL.PRO) {
        // Send response to database
        this.userProgress = 5
      }
    } else if (this.userProgress === 5) {
      if (this.userLevel === USER_LEVEL.PRO) {
        // Send response to database
        this.userProgress = 9
      } else {
        this.userProgress += 1
      }
    } else {
      this.userProgress += 1 || 15
    }
  }

  public async previousQuestion (): Promise<void> {
    // Pause player when a user change of question
    if (this.$store.state.playingAudio) {
      await this.$store.dispatch('updatePlayingAudio', false)
    }
    /* Logic to go back to the question you came from (See 'submitQuestion' method logic) */
    if (this.userProgress === 0) {
      await this.$router.push({ name: 'Home' })
    } else if (this.userProgress === 4) {
      if (this.userLevel === USER_LEVEL.NORMAL) {
        this.userProgress = 2
      } else {
        this.userProgress -= 1 || 0
      }
    } else if (this.userProgress === 5) {
      if (this.userLevel === USER_LEVEL.PRO) {
        this.userProgress = 3
      } else {
        this.userProgress -= 1 || 0
      }
    } else if (this.userProgress === 9) {
      if (this.userLevel === USER_LEVEL.PRO) {
        this.userProgress = 5
      } else {
        this.userProgress -= 1 || 0
      }
    } else {
      this.userProgress -= 1 || 0
    }
  }

  async updateQuizInformation (quizId: string): Promise<void> {
    try {
      const docRef = doc(db, 'quiz', quizId)
      this.currentQuiz.date = new Date()
      this.loading = true
      await updateDoc(docRef, this.currentQuiz)
      this.loading = false
    } catch (e) {
      console.log(e)
    }
  }

  get userLevel (): string {
    if (this.questions[0].response === 1 || this.questions[0].response === 2) {
      return USER_LEVEL.NORMAL
    } else if (this.questions[0].response === 3) {
      return USER_LEVEL.PRO
    } else {
      return USER_LEVEL.NOOB
    }
  }

  get userPriority (): string {
    if (this.userLevel === USER_LEVEL.NOOB) {
      return ''
    } else {
      if (this.questions[13].response === 1) {
        return QUIZ_PRIORITY.NORMAL
      } else if (this.questions[13].response === 2) {
        return QUIZ_PRIORITY.LOW
      } else if (this.questions[13].response === 0) {
        return QUIZ_PRIORITY.HIGH
      } else {
        return QUIZ_PRIORITY.LOW
      }
    }
  }

  get questionLength (): number {
    return this.questions.length + 2
  }

  getDeviceType (): string {
    return window.innerWidth <= 784 ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.DESKTOP
  }

  // -2 because there are two static questions
  get currentQuestionIndex (): number {
    return this.userProgress > 2 ? this.userProgress - 2 : 0
  }

  get showSubmitButton (): boolean {
    if (this.currentQuestionIndex < 2 || this.currentQuestionIndex >= this.questions.length) {
      return false
    }
    return this.questions[this.currentQuestionIndex].showSubmitButton
  }

  async getCityName (): Promise<string> {
    const response = await fetch(`https://api.ipdata.co?api-key=${process.env.VUE_APP_APIKEY_IPDATA}`)
    const countryObject = await response.json()
    return `${countryObject.city} - ${countryObject.region}`
  }

  async saveQuizInformation (): Promise<void> {
    try {
      this.loading = true
      const quizCounter = await this.getQuizCounter()
      if (quizCounter !== -1) {
        this.currentQuiz = {
          code: quizCounter + 1,
          date: new Date(),
          device: this.getDeviceType(),
          email: this.currentQuiz.email,
          name: this.currentQuiz.name,
          phone: this.currentQuiz.phone,
          city: await this.getCityName(),
          priority: QUIZ_PRIORITY.LOW,
          status: QUIZ_STATUS.INCOMPLETE
        }
        const quizResponse = await addDoc(collection(db, 'quiz'), this.currentQuiz)
        this.currentQuiz.id = quizResponse.id

        // Update quiz counter
        await setDoc(doc(collection(db, 'counters'), 'quiz'), {
          counter: this.currentQuiz.code
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  }

  async getQuizCounter (): Promise<number> {
    const docRef = doc(db, 'counters', 'quiz')
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      return docSnap.data().counter
    } else {
      return -1
    }
  }

  async getAnswer (question: IQuestion): Promise<string> {
    if (question) {
      if (question.answerType === ANSWER_TYPE.AUDIO) {
        if (this.tempBlob) {
          const name = `audio-responses/${this.currentQuiz.id}-${this.currentQuiz.email}-question(${this.currentQuestionIndex + 1}).webp`
          const storageRef = ref(getStorage(app), name)
          await uploadBytes(storageRef, this.tempBlob)
          return name
        } else {
          return ''
        }
      }
      if (question.answerType === ANSWER_TYPE.ALTERNATIVE) {
        const reponseToInteger = Number(Number.isInteger(question.response) ? question.response : 0)
        if (question.alternatives && question.alternatives.length > 0) {
          return question.alternatives[reponseToInteger]?.statement
        } else {
          return ''
        }
      } else {
        // when a answer type is text
        return question.response.toString()
      }
    } else {
      return ''
    }
  }

  async sendCurrentAnswer (): Promise<void> {
    try {
      if (this.currentQuiz) {
        this.loading = true
        const currentQuestion: IQuestion = this.questions[this.currentQuestionIndex]
        if (currentQuestion) {
          const answer = {
            questionIndex: this.currentQuestionIndex + 1,
            questionType: currentQuestion.questionType,
            question: currentQuestion.statement,
            answer: await this.getAnswer(currentQuestion),
            answerType: currentQuestion.answerType
          }

          if (currentQuestion.answerId) {
            const docRef = doc(db, `quiz/${this.currentQuiz.id}/answers`, currentQuestion.answerId)
            await updateDoc(docRef, answer)
          } else {
            const answerResponse = await addDoc(collection(db, `quiz/${this.currentQuiz.id}/answers`), answer)
            currentQuestion.answerId = answerResponse.id
          }
          this.tempBlob = null
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  }

  saveTempBlob (event: Blob): void {
    this.tempBlob = event
  }

  mounted (): void {
    window.onbeforeunload = () => {
      if (this.userProgress !== 0 && this.userProgress !== 16) return 'Are you sure you want to leave?'
    }
    this.getCityName()
  }
}
