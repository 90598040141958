
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TSLCButton extends Vue {
  @Prop() private height!: number
  @Prop() private width!: number
  @Prop() private elevation!: number
  @Prop() private type!: string
  @Prop({ default: false }) private loading!: boolean
}
